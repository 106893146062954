<template>
  <div>
    <div v-if="!isReal" class="mb-4 items-center">
      <vs-button class="ml-auto" @click="popupReviewForm = true;" size="small">Add New Review</vs-button>
    </div>
    <vx-card no-shadow>
      <div id="reviews-table-loading" class="vs-con-loading__container">
        <vs-table
          :data="reviews">
          <template slot="header">
            <div class="md:flex py-2 md:py-4 w-full">
              <h5 class="flex flex-grow items-center mb-2 md:mb-0">
                <feather-icon icon="AwardIcon" class="w-5 h-5 mr-1"></feather-icon>
                <span> {{ isReal ? "Reviews" : "Flip Reviews" }} </span>
              </h5>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
              v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
            </div>
          </template>
          <template slot="thead">
            <vs-th>
              Rating
            </vs-th>
            <vs-th>
              Product
            </vs-th>
            <vs-th>
              Published at
            </vs-th>
            <vs-th>
              Action
            </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="data[indextr].rating">
                <span class="flex"><feather-icon icon="StarIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
                {{data[indextr].rating}}</span>
              </vs-td>
              <vs-td :data="data[indextr].product.name">
                <span class="text-info cursor-pointer" @click="$router.push({path: '/products/'+data[indextr].product_id})">{{data[indextr].product.name}}</span>
              </vs-td>

              <template v-if="data[indextr].is_real">
                <vs-td :data="data[indextr].created_at">
                  {{data[indextr].created_at | date_parse(true)}}
                </vs-td>
              </template>
              <template v-else>
                <vs-td :data="data[indextr].reviewed_at">
                  {{data[indextr].reviewed_at | date_parse(true)}}
                </vs-td>
              </template>
              <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="editItem(tr)" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteItem(tr.id)" />
              </vs-td>
              <template slot="expand">
                <div class="con-expand-users w-full p-3">
                  <div class="vx-row">
                    <div class="vx-col sm:w-2/3 w-full mb-4 sm:mb-0">
                      <div class="mb-3">
                        <h5><strong> Review Details </strong></h5>
                        <p class="mb-1">{{data[indextr].content}}</p>
                        <small class="d-block opacity-50">Published: {{data[indextr].created_at | date(true)}} </small>
                      </div>
                      <div class="pl-5" v-if="data[indextr].responder_id">
                        <h5 class="capitalize"> Admin response</h5>
                        <p class="mb-1">{{data[indextr].response}}</p>
                        <small class="d-block opacity-50">Published: {{data[indextr].responded_at | date(true)}} </small>
                      </div>
                      <div class="con-btns-user py-3" v-else>
                        <vs-button size="small" color="primary" @click="editItem(tr)" class="mr-2"> Send Response</vs-button>
                      </div>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                      <div v-if="data[indextr].is_real"> 
                        <div class="mb-3" v-if="data[indextr].user.image">
                          <img @click="$router.push({path: '/users/'+data[indextr].user.id})" class="w-12" :src="absoluteUrl(data[indextr].user.image)" alt="Client image" />
                            {{data[indextr].user.first_name}}
                        </div>
                        <div class="mb-3" v-if="data[indextr].user.country.name">
                          <span class="flex"><img style="height:15px;" class="mr-1" :src="absoluteUrl()+'/images/country_flags/'+data[indextr].user.country.icon" alt="star" />
                          {{data[indextr].user.country.name}}</span>
                        </div>
                        <div>
                          <strong>Order Reviewed at: </strong>
                          <span class="text-info cursor-pointer" @click="$router.push({path:'/orders/'+data[indextr].order_id})">#{{data[indextr].order_id}}</span>
                        </div>
                      </div>
                      <div v-else>
                        <div class="mb-3" v-if="data[indextr].reviewer_image">
                          <img class="w-12" :src="absoluteUrl(data[indextr].reviewer_image)" alt="Client image" />
                          {{data[indextr].reviewer_name}}
                        </div>
                        <div v-if="data[indextr].country.name">
                          <span class="flex"><img style="height:15px;" class="mr-1" :src="absoluteUrl()+'/images/country_flags/'+data[indextr].country.icon" alt="star" />
                          {{data[indextr].country.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          :total="pageData.last_page || 1"
          :max="7"
          v-model="currentPage" />
      </div>
    </vx-card>

    <vs-popup fullscreen class="holamundo" @close="initializeAll"
     :title="editMode? 'Update Review': 'Add Review'"
     :active.sync="popupReviewForm">
      <div class="mt-2">
        <review-form v-if="popupReviewForm" :edit-item="showItem"
        @closeform="initializeAll()" :edit-mode="editMode" />
      </div>
    </vs-popup>
  </div>
</template>
<script>

import ReviewForm from '@/components/forms/ReviewForm.vue'
import _ from 'lodash';

export default {
  components: {
    ReviewForm
  },
  data() {
    return {
      popupReviewForm: false,
      editMode: false,
      showItem: null,
      isReal:1,
      searchQuery: "",
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus",
    isReal (val) {
      this.fetchReviews()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.reviewList.pageData
    },
    reviews() {
      return this.pageData.data.filter(r => r.is_real == this.isReal)
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchReviews(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchReviews(1, val)
      }
    },
  },
  methods:{
    refreshQueryStatus(){
      if(this.$route.query.is_real){
        this.isReal = parseInt(this.$route.query.is_real)
      }
    },
    editItem(item){
      this.showItem = {...item};
      this.editMode = true
      this.popupReviewForm = true
    },
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The selected review will be deleted!',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$vs.loading({color: "#444", type:'sound'})
      this.$http.delete(`/reviews/${itemId}/delete`)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit('reviewList/DELETE_REVIEW', itemId)
          this.initializeAll();
        }
      })
    },
    initializeAll(){
      this.popupResponse = false
      this.$validator.reset()
      this.popupReviewForm = false
      this.editMode = false
      this.showItem = null
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchReviews()
    }, 2000),
    fetchReviews(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage , is_real: this.isReal}
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#reviews-table-loading', color: "#444", type: "sound"})
      this.$store.dispatch("reviewList/fetchReviews", payload)
      .then((response) => {
        this.$vs.loading.close('#reviews-table-loading > .con-vs-loading')
     })
    },
  },
  mounted(){
    this.refreshQueryStatus()
    if(this.isReal){this.fetchReviews()}
    this.$store.dispatch('fetchCountries')
  }
}
</script>

<style type="text/css">
  .vue-star-rating-star{
    height: 25px;
    width:25px;
  }
  .vs-con-textarea{
    margin-bottom: 0;
  }
</style>
